<template>
  <img alt="Vue logo" src="./assets/logo.png">
  <HelloWorld msg="Welcome to www.haotools.top"/>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
document.addEventListener('keydown', function(event) {
  if ((event.ctrlKey || event.metaKey) && event.shiftKey && event.key === 'I') {
    event.preventDefault();
    alert("不让你进开发者工具！");
  }
  if (event.key === 'F12') {
    event.preventDefault();
    alert("就你，还想F12?");
  }
});
// 禁用右键菜单
document.addEventListener('contextmenu', (event) => {
  event.preventDefault();
  alert("别点了，右键被禁止了!");
});
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
