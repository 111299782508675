<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <p>
      <a href="http://ruoyi.haotools.top/" target="_blank" rel="noopener">后台管理</a> &nbsp;
      <a href="http://app.haotools.top/" target="_blank" rel="noopener">APP</a> &nbsp;
      <a href="http://sou.haotools.top/" target="_blank" rel="noopener">资源搜索</a>
    </p>
    <p class="beian">
      备案号：<a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener">陕ICP备2024034377号</a>
    </p>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a {
  color: #42b983;
}
.beian {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
</style>
